import * as React from "react";

function DotsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 117 75"
      fill="white"
      {...props}
    >
      <path
        d="M5.25 10.8c-7 0-7-10.8 0-10.8s7 10.8 0 10.8zM36.05 8.6V2.2c1.2 1.9 1.2 4.5 0 6.4zm-8.7 0c-1.2-1.9-1.2-4.5 0-6.3v6.3zM31.75 10.8c-2 0-3.5-.9-4.3-2.2V2.2c.8-1.3 2.2-2.2 4.3-2.2 2 0 3.5.9 4.3 2.2v6.4c-.9 1.3-2.3 2.2-4.3 2.2zM58.15 10.8c-7 0-7-10.8 0-10.8s7 10.8 0 10.8zM84.55 10.8c-7 0-7-10.8 0-10.8s7 10.8 0 10.8zM111.05 10.8c-7 0-7-10.8 0-10.8 6.9 0 6.9 10.8 0 10.8zM5.25 32.2c-7 0-7-10.8 0-10.8s7 10.8 0 10.8zM36.05 30v-6.4c1.2 1.9 1.2 4.5 0 6.4zm-8.7 0c-1.2-1.9-1.2-4.5 0-6.3V30zM31.75 32.2c-2 0-3.5-.9-4.3-2.2v-6.3c.8-1.3 2.3-2.2 4.3-2.2s3.5.9 4.3 2.2V30c-.9 1.3-2.3 2.2-4.3 2.2zM58.15 32.2c-7 0-7-10.8 0-10.8s7 10.8 0 10.8zM84.55 32.2c-7 0-7-10.8 0-10.8s7 10.8 0 10.8zM111.05 32.2c-7 0-7-10.8 0-10.8 6.9 0 6.9 10.8 0 10.8zM5.25 53.6c-7 0-7-10.8 0-10.8s7 10.8 0 10.8zM36.05 51.4V45c1.2 1.9 1.2 4.5 0 6.4zm-8.7 0c-1.2-1.9-1.2-4.5 0-6.3v6.3zM31.75 53.6c-2 0-3.5-.9-4.3-2.2V45c.8-1.3 2.3-2.2 4.3-2.2s3.5.9 4.3 2.2v6.4c-.9 1.3-2.3 2.2-4.3 2.2zM58.15 53.6c-7 0-7-10.8 0-10.8s7 10.8 0 10.8zM84.55 53.6c-7 0-7-10.8 0-10.8s7 10.8 0 10.8zM111.05 53.6c-7 0-7-10.8 0-10.8 6.9 0 6.9 10.8 0 10.8zM5.25 75c-7 0-7-10.8 0-10.8s7 10.8 0 10.8zM36.05 72.8v-6.4c1.2 1.9 1.2 4.5 0 6.4zm-8.7 0c-1.2-1.9-1.2-4.5 0-6.3v6.3zM31.75 75c-2 0-3.5-.9-4.3-2.2v-6.3c.8-1.3 2.3-2.2 4.3-2.2s3.5.9 4.3 2.2v6.4c-.9 1.2-2.3 2.1-4.3 2.1zM58.15 75c-7 0-7-10.8 0-10.8s7 10.8 0 10.8zM84.55 75c-7 0-7-10.8 0-10.8s7 10.8 0 10.8zM111.05 75c-7 0-7-10.8 0-10.8 6.9 0 6.9 10.8 0 10.8z"
      />
    </svg>
  );
}

export default DotsIcon;
