import styled from 'styled-components';
import Img from 'gatsby-image';
import RichText from '../rich-text';
import { Link } from 'gatsby';
import { OrangeCTA } from '../buttons/style';

export const Wrapper = styled.section`
  display: flex;

  ${({ theme }) =>
    theme.breakpoints.under_m(`
      flex-direction: column;
      align-items: center;
    `)}

  > * {
    flex: 1;
  }
`;

export const StyledImg = styled(Img)`
  margin-right: 38px;
  ${({ theme }) =>
    theme.breakpoints.under_m(`
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    `)}
`;
export const StyledRichText = styled(RichText)`
  ${({ theme }) =>
    theme.breakpoints.under_m(`
    margin-top: 20px;
`)}
  p {
    ${({ theme }) =>
      theme.breakpoints.under_m(`
        text-align: center;
    `)}
    text-align: justify;
  }
  > * {
    color: ${({ theme }) => theme.color.grey.hex};
  }
`;

export const RightText = styled.div`
  ${({ theme }) =>
    theme.breakpoints.under_m(`
      text-align: center;
`)}
`;

export const ContactButton = styled(Link)`
  ${OrangeCTA}
  margin: 20px auto;
  display: inline-block;

  &:hover {
    margin: 20px auto;
  }
`;
