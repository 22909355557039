import * as React from 'react';
import { FluidObject } from 'gatsby-image';
import { IRichText } from '../../pages';
import { Wrapper, StyledImg, StyledRichText, ContactButton, RightText } from './style';

export interface IHomeDescriptionProps {
  descriptionImage: {
    title: string;
    fluid: FluidObject;
  };
  bio: IRichText;
  className?: string;
}

const HomeDescription: React.FC<IHomeDescriptionProps> = ({ descriptionImage, bio, className }) => {
  //    const [state, setstate] = useState(undefined);
  return (
    <Wrapper className={className}>
      {/* @ts-ignore */}
      <StyledImg alt={descriptionImage.title} fluid={descriptionImage.fluid} imgStyle={{ objectFit: 'contain' }} />
      <RightText>
        <StyledRichText richText={bio} />
        <ContactButton to="/contact">Me contacter</ContactButton>
      </RightText>
    </Wrapper>
  );
};

export default HomeDescription;
